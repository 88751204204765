<template>
  <div>
    <div class="home-title">
      <p>hi, {{ username }}</p>
      <p>欢迎回来</p>
      <p>上次登录：{{ lastLoginTime }}</p>
    </div>
    <el-row :gutter="16">
      <el-col :span="8">
        <div class="home-mid">
          <div class="home-mid-left" v-loading="numLoading">
            <img src="./img/org.png" alt="" />
            <div class="content">
              <p>机构</p>
              <div class="num">
                <span>{{ orgNum }}</span>
                <span>条</span>
              </div>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="8">
        <div class="home-mid">
          <div class="home-mid-left" v-loading="numLoading">
            <img src="./img/doc.png" alt="" />
            <div class="content">
              <p>医疗专业人士</p>
              <div class="num">
                <span>{{ docNum }}</span>
                <span>条</span>
              </div>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="8">
        <div class="home-mid">
          <div class="home-mid-right" v-loading="viewLoading">
            <MyTab :tab-list="tabList" @clickItem="handleChange"></MyTab>
            <div v-if="tabIndex === 0" class="browse">
              <div class="browse-scroll">
                <div v-for="(item, i) in recentlyViewed" :key="i">
                  <div class="browse-content" @click="handleToDataDetail(item)">
                    <p>{{ item.name }}</p>
                    <el-tag class="tags" size="small" :color="tagColorOptions[item.type]">
                      {{ item.type }}
                    </el-tag>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="tabIndex == 1" class="search">
              <div v-for="(item, i) in recentSearch" :key="i">
                <span class="keywords" @click="handleToSearch(item)">{{ item }}</span>
              </div>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>

    <el-row :gutter="16">
      <el-col :span="16">
        <div class="home-bottom">
          <div class="home-bottom-left" v-loading="lineLoading">
            <div class="select-title">
              <p class="title">最新动态</p>
              <div class="select-title-right">
                <el-select v-model="orgType" class="select-type" @change="handleChangeOrg">
                  <el-option
                    v-for="item in typeOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
                <div class="select-date">
                  <p
                    v-for="(item, i) in orgTabList"
                    :key="i"
                    :class="{ 'is-active': orgTabIndex === i }"
                    @click="handleOrgChange(i)"
                  >
                    {{ item }}
                  </p>
                </div>
              </div>
            </div>
            <div id="orgNews"></div>
          </div>
        </div>
      </el-col>
      <el-col :span="8">
        <div class="home-bottom">
          <div class="home-bottom-right" v-loading="pieLoading">
            <div class="tit">
              <p class="home-bottom-right-title">机构数据分布</p>
            </div>
            <div id="orgDatas"></div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import store from '@/store';
import login from '@/libs/login';
import { GetMdmUserInfo } from '@/api/user';
import { getDate, dateFormat } from '@/utils/util';
import moment from 'moment';
import MyTab from './components/tab.vue';
import {
  GetLatestDevelopments,
  GetDataPieChart,
  // GetDataStatistics,
  GetRecentView,
} from '@/api/home';
import { SearchMainDataByType } from '@/api/data';
export default {
  name: 'Home',
  components: {
    MyTab,
  },
  data() {
    return {
      lineLoading: false,
      pieLoading: false,
      orgTot: '',
      hosTot: '',

      username: '',
      lastLoginTime: '',
      tabList: ['最近浏览', '最近搜索'],
      tabIndex: 0,
      orgType: 1,
      typeOptions: [
        { label: '机构', value: 1 },
        { label: '医疗专业人士', value: 0 },
      ],
      orgTabList: ['近一周', '近一月', '近三个月', '近一年'],
      orgTabIndex: 0,
      startTime: '',

      // 折线图配置
      newsOption: {},

      // 饼图配置
      orgDatasOption: {},
      // add: [],
      // del: [],
      // update: [],
      uin: '',

      orgNews: null,
      orgDatas: null,
      orgNum: '',
      docNum: '',
      numLoading: false,
      tagColorOptions: {
        医生: '#E8F4FF',
        医院: '#C5FBCE',
        经销商: '#F0DCFF',
        基层医疗卫生机构: '#FAF1E1',
        专业公共医疗卫生机构: '#FFE6E6',
        药店: '#D0F7FF',
        其他分类: '#E5EAF2',
      },
      recentlyViewed: [],
      recentSearch: [],
      viewLoading: false,
    };
  },
  computed: {},
  watch: {
    $route(to, from) {
      const { path } = from;
      // 路由从登陆页跳转回本页面时重置信息
      if (path === '/login') {
        this.init();
      }
    },
  },
  created() {
    this.getNums();
    this.uin = login.getToken().uin;
    this.getViewed();
  },
  mounted() {
    this.orgNews = this.$echarts.init(document.getElementById('orgNews'));
    this.orgDatas = this.$echarts.init(document.getElementById('orgDatas'));

    this.startTime = `${moment(new Date())
      .subtract(1, 'weeks')
      .format('YYYY-MM-DD')} 00:00:00`;
    this.getNews();
    this.getOrgDatas();

    window.onresize = () => {
      this.orgNews.resize();
      this.orgDatas.resize();
    };

    this.init();
    store.commit('user/setBreadcrumb', [{ name: 'Dashboard', path: 'dashboard/home' }]);
  },
  methods: {
    // 跳转数据详情
    handleToDataDetail(val) {
      const type = {
        医院: 'hospital',
        基层医疗卫生机构: 'hospital-ji',
        专业公共卫生机构: 'hospital-pro',
        其他医疗卫生机构: 'hospital-other',
        经销商: 'dealer',
        药店: 'shop',
        生产企业: 'manufacturer',
        医疗器械: 'medical-machine',
        其他分类: 'other',
        医生: 'doctor',
        中转区机构: 'mid-org',
        中转区医疗专业人士: 'mid-doc',
      };
      console.log(val);
      // this.$router.push({
      //   name: 'data-detail',
      //   query: { id: val.id, source: type[val.type], name: val.name, type: 'preview' },
      // });

      const href = this.$router.resolve({
        path: '/dashboard/data-detail',
        query: {
          id: val.id,
          name: val.name,
          type: 'preview',
          source: type[val.type],
        },
      });
      window.open(href.href, '_blank');
    },
    // 跳转全局搜索
    handleToSearch(val) {
      this.$router.push({
        name: 'search',
        query: { text: val, type: 'all' },
      });
    },
    // 获取最近浏览或最近搜索
    getViewed() {
      this.viewLoading = true;
      const req = {
        uin: this.uin,
        view_type: this.tabIndex + 1,
      };
      GetRecentView(req).then(res => {
        this.recentlyViewed = res.search_data;
        this.recentSearch = res.keywords;
        this.viewLoading = false;
      });
    },
    // 获取机构与医疗专业人士总数量
    getNums() {
      this.numLoading = true;
      // 查询hco参数
      const hcoReq = {
        main_data: 't_hco',
        data: '',
        keyword: '',
        index: 1,
        page_size: 20,
        search_fields: ['name'],
        SearchOptions: [],
        data_array: [
          '医院',
          '基层医疗卫生机构',
          '专业公共卫生机构',
          '其他医疗卫生机构',
          '经销商',
          '药店',
          '医疗器械',
          '生产企业',
          '其他分类',
        ],
      };
      // 查询hcp参数
      const hcpReq = {
        main_data: 't_hcp',
        data: '',
        keyword: '',
        index: 1,
        page_size: 20,
        search_fields: ['name'],
        SearchOptions: [],
        data_array: ['医生'],
      };
      const hco = new Promise(resolve => {
        SearchMainDataByType(hcoReq).then(res => {
          resolve(res.total_num);
        });
      });
      const hcp = new Promise(resolve => {
        SearchMainDataByType(hcpReq).then(res => {
          resolve(res.total_num);
        });
      });
      Promise.all([hco, hcp]).then(res => {
        console.log(res);
        this.orgNum = res[0].toLocaleString();
        this.docNum = res[1].toLocaleString();
        this.numLoading = false;
      });
    },
    // 获取饼图数据
    getOrgDatas() {
      this.pieLoading = true;
      const req = {
        search_type: this.orgType,
      };
      GetDataPieChart(req).then(res => {
        const data = [];
        const name = [];
        res.data.forEach(item => {
          data.push({
            value: item.total,
            name: item.type,
          });
          name.push(item.type);
        });
        // let i = -1;
        const orgDatasOption = {
          legend: {
            bottom: 0,
            left: 'center',
            // formatter() {
            //   i += 1;
            //   return `${name[i]}`;
            // },
          },
          tooltip: {
            trigger: 'item',
            formatter: '{b}: {c} ({d}%)',
          },
          color: ['#FCBF2F', '#E55D8F', ' #1977FA', '#16BFCA', '#846BCE'],
          series: [
            {
              type: 'pie',
              // radius: '50%',
              // radius: [50, 120],
              // center: ['50%', '50%'],
              // roseType: 'area',
              // label: {
              //   position: 'inner',
              //   fontSize: 14,
              // },
              radius: ['25%', '45%'],
              avoidLabelOverlap: true,
              itemStyle: {
                borderRadius: 5,
                borderColor: '#fff',
                borderWidth: 2,
              },
              label: {
                show: true,
                // position: 'center',
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: '16',
                  // fontWeight: 'bold'
                },
              },
              labelLine: {
                show: true,
              },
              data,
            },
          ],
        };

        // const orgDatas = this.$echarts.init(document.getElementById('orgDatas'));
        // 绘制图表
        this.orgDatas.setOption(orgDatasOption);
        this.pieLoading = false;
      });
    },
    // 切换机构
    handleChangeOrg() {
      this.getNews();
      this.getOrgDatas();
    },
    // 获取最新动态图
    getNews() {
      this.lineLoading = true;
      const endtTime = `${dateFormat(new Date(), 'YYYY-MM-dd')} 23:59:59`;
      const req = {
        search_type: this.orgType,
        start_time: this.startTime,
        end_time: endtTime,
      };
      GetLatestDevelopments(req).then(res => {
        // console.log('最新动态', res);
        const add = [];
        const del = [];
        const update = [];
        const time = [];
        res.data.forEach(item => {
          time.push(item.time);
          add.push(item.create_count);
          del.push(item.delete_count);
          update.push(item.update_count);
        });
        const newsOption = {
          tooltip: {
            trigger: 'axis',
          },
          legend: {
            top: 'bottom',
          },
          grid: {
            left: '2%',
            right: '4%',
            bottom: '8%',
            containLabel: true,
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: time,
          },
          yAxis: {
            type: 'value',
          },
          color: ['#0069FA', '#02BECA', '#98A2B3'],
          series: [
            {
              name: '新增',
              type: 'line',
              smooth: true,
              data: add,
            },
            {
              name: '更新',
              type: 'line',
              smooth: true,
              data: update,
            },
            {
              name: '删除',
              type: 'line',
              smooth: true,
              data: del,
            },
          ],
        };
        // const orgNews = this.$echarts.init(document.getElementById('orgNews'));
        // 绘制图表
        this.orgNews.setOption(newsOption);
        this.lineLoading = false;
      });
    },

    handleOrgChange(val) {
      if (val !== this.orgTabIndex) {
        this.orgTabIndex = val;
        if (this.orgTabIndex === 0) {
          this.startTime = `${moment(new Date())
            .subtract(1, 'weeks')
            .format('YYYY-MM-DD')} 00:00:00`;
          this.getNews();
        }
        if (this.orgTabIndex === 1) {
          this.startTime = `${moment(new Date())
            .subtract(1, 'months')
            .format('YYYY-MM-DD')} 00:00:00`;
          this.getNews();
        }
        if (this.orgTabIndex === 2) {
          this.startTime = `${moment(new Date())
            .subtract(3, 'months')
            .format('YYYY-MM-DD')} 00:00:00`;
          this.getNews();
        }
        if (this.orgTabIndex === 3) {
          this.startTime = `${moment(new Date())
            .subtract(1, 'years')
            .format('YYYY-MM-DD')} 00:00:00`;
          this.getNews();
        }
      }
    },
    handleChange(val) {
      this.tabIndex = val;
      this.getViewed();
    },
    init() {
      // home页面需要sessionId显示上次登陆时间 如果获取不到就需要重新登陆
      const sessionId = login.getSession();
      if (sessionId === null) {
        this.$message.error('登陆时间异常，请重新登陆');
        this.$router.push('/login');
        return;
      }

      // 获取用户信息时带上session_id获得正确的用户上次登陆时间
      GetMdmUserInfo({ session_id: sessionId }).then(res => {
        this.username = res.user_name;
        store.commit('user/setUserName', res.user_name);

        if (res.last_login_time === 0) {
          this.lastLoginTime = '无';
        } else {
          const time = new Date(res.last_login_time * 1000);
          const { year, month, date, hour, minute } = getDate(time);
          this.lastLoginTime = `${year}/${month}/${date}  ${hour}:${minute}`;
        }
      });

      // 判断是否是客户端环境还是管理端环境
      this.getSystemEnv();
    },

    // todo: 前端暂时用host去判断，如果客户端域名发生变化，这里需要由后端控制
    // 存储在localStorage，systemEnv为1表示管理端，2表示客户端
    getSystemEnv() {
      if (window.location.host.indexOf('demo') > -1) {
        console.log('我是客户端环境');
        localStorage.setItem('systemEnv', 2);
      } else {
        console.log('我是管理端环境');
        localStorage.setItem('systemEnv', 1);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import './home.scss';
</style>
