<!--  -->
<template>
  <div class="tabs-bar">
    <div
      v-for="(item, i) in tabList"
      :key="i"
      class="bar-item"
      :class="{ 'is-active': tabIndex === i }"
      @click="handleChange(i)"
    >
      {{ item }}
    </div>
    <div class="line"></div>
  </div>
</template>

<script>
export default {
  props: {
    current: {
      type: Number,
      default: 0,
    },
    tabList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      tabIndex: 0,
    };
  },
  watch: {
    current(val) {
      if (val !== this.tabIndex) {
        this.tabIndex = val;
      }
    },
  },
  created() {
    this.tabIndex = this.current;
  },
  methods: {
    handleChange(i) {
      if (this.tabIndex !== i) {
        this.tabIndex = i;
        this.$emit('clickItem', i);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.tabs-bar {
  flex-shrink: 0;
  display: flex;
  background: #fff;
  position: relative;
  .bar-item {
    position: relative;
    // flex: 1;
    // width: 16%;
    width: 128px;
    min-width: 64px;
    height: 50px;
    font-size: 16px;
    color: rgba(0, 15, 30, 0.9);
    text-align: center;
    line-height: 50px;
    cursor: pointer;
    &.is-active {
      font-weight: bold;
      &::after {
        content: ' ';
        position: absolute;
        left: 50%;
        bottom: 0;
        z-index: 999;
        width: 64px;
        height: 1px;
        background: #2f96ff;
        transform: skewX(-30deg) translateX(-50%);
      }
    }
  }
}
.line {
  position: absolute;
  left: 0;
  top: 50px;
  background: #e5eaf2;
  width: 100%;
  height: 1px;
}
</style>
